@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Playwrite+US+Modern:wght@100..400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&display=swap');

/* html, body, #root, .App {
  height: 100%;
  margin: 0;
  overflow: x-hidden;
  position: relative;
  font-family: 'Amatic SC', sans-serif;
  background-color: #66ccff;
}

.background-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
}

.background-image {
  position: absolute;
  bottom: -100%;
  left: 50%;
  transform: translateX(-50%);
  width: 200px;
  opacity: 0.7; 
  animation: moveUp 30s linear infinite;
}

@keyframes moveUp {
  0% {
    bottom: 0%;
  }
  100% {
    bottom: 100%;
  }
} */

/* html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: 'Amatic SC', sans-serif;
} */

html, body, #root, .App {
  height: 100%;
  margin: 0;
  overflow: x-hidden;
  font-family: 'Amatic SC', sans-serif;
}

/* .background-fixed {
  background-image: url('/public/images/Sky.png');
  background-size: 3000px;
  background-repeat: repeat-x;
  background-position: top left;
  animation: scrollBackground 30s linear infinite;
} */

/* @media (max-width: 768px) {
  .background-fixed {
    background-image: url('/images/1884-small.webp');
  }
} */

@keyframes scrollBackground {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 100% 0;
  }
}

/* html, body, #root, .App {
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
  font-family: 'Amatic SC', sans-serif;
}

.background-fixed {
  background-image: url('/public/images/sky_background.png');
  background-size: cover;
  background-repeat: repeat-x;
  background-position: top left;
  animation: scrollBackground 30s linear infinite;
}

@keyframes scrollBackground {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 0 -1000px;
  }
} */

.amatic-sc-regular {
  font-family: "Amatic SC", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.amatic-sc-bold {
  font-family: "Amatic SC", sans-serif;
  font-weight: 700;
  font-style: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.playwrite-us-modern {
  font-family: "Playwrite US Modern", cursive;
  font-optical-sizing: auto;
  font-weight: 100;
  font-style: normal;
}