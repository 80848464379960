@import url('https://fonts.googleapis.com/css?family=Exo:400,700');

* {
  margin: 0px;
  padding: 0px;
}

body {
  font-family: 'Exo', sans-serif;
}

.area {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  /* background-color: #06b6d4; */
  background-color: #a5b4fc;
  background: -webkit-linear-gradient(to left, #a5b4fc, #4338ca);
}

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  background: rgba(255, 255, 255, 0.7); 
  border-radius: 50%; 
}

.circle-image {
  width: 80%;
  height: 80%;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid white;
}

.static-circle {
  animation: none;
  opacity: 0.5;
}

.moving-circle {
  animation: animate 25s linear infinite;
  bottom: -150px;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 50%;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0.2;
    border-radius: 100%;
  }
}
